.planning-container {
  padding: 2rem;
  max-width: 1200px;  /* Increased from 800px */
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.events-timeline {
  scrollbar-width: thin;
  scrollbar-color: #dedede #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.events-timeline::-webkit-scrollbar {
  width: 8px;
}

.events-timeline::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.events-timeline::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 4px;
}

/* Professional styling for the upcoming event */
.event-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  border-left-width: 4px;
  border-left-style: solid;
  cursor: pointer;
  min-height: 72px;
  display: flex;
  align-items: center;
}

/* Enhanced professional styling for the upcoming event */
/* Make sure the upcoming event highlighting works */
.upcoming-event {
  position: relative;
  background-color: #f9fafd;
  transform: translateX(2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08) !important;
  border-left-width: 6px !important;
}

/* Remove any unwanted triangles or markers */
.upcoming-event::before {
  display: none !important;
}

/* Professional badge for upcoming event */
.upcoming-event::after {
  content: "VOLGENDE";
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #3b82f6;
  padding: 4px 8px;
  border-radius: 0 8px 0 8px;
  z-index: 10; /* Ensure it's above other elements */
}

.planning-header {
  margin-bottom: 2rem;
}

.planning-header h1 {
  color: #1a1a1a;
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.planning-title {
  text-align: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.event-card:hover {
  transform: translateX(4px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Make sure the upcoming event keeps its highlight even when other events are hovered */
.event-card:hover:not(.upcoming-event) {
  transform: translateX(4px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upcoming-event:hover {
  transform: translateX(6px) !important;
}

.event-reason {
  font-size: 0.85rem;
  color: #666;
  margin-top: 0.25rem;
}

.event-grid {
  display: grid;
  grid-template-columns: 140px 120px minmax(300px, 1fr) 150px;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.event-date {
  font-size: 0.9rem;
  color: #718096;
  font-weight: 500;
}

.event-time {
  font-size: 0.85rem;
  color: #4a5568;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: #3b82f6;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
}

.event-title {
  font-size: 1rem;
  color: #2d3748;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.event-description {
  font-size: 0.9rem;
  color: #718096;
}

.loading-spinner {
  text-align: center;
  padding: 2rem;
  color: #4a5568;
}

.event-subitems {
  text-align: right;
  padding-left: 1rem;
  border-left: 2px solid #e2e8f0;
  min-width: 150px;
}

.event-subitem {
  padding: 0.1rem 0;
  color: #4a5568;
  font-size: 0.9rem;
  white-space: nowrap;
}

.event-subitem:last-child {
  margin-bottom: 0;
}

.subitem-title {
  font-size: 0.9rem;
  color: #4a5568;
  font-weight: 500;
}

.subitem-id {
  font-size: 0.8rem;
  color: #718096;
  margin-top: 0.25rem;
}

.no-events {
  text-align: center;
  padding: 2rem;
  color: #718096;
  font-style: italic;
}

.month-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.month-navigation h2 {
  font-size: 1.25rem;
  color: #4a5568;
  margin: 0;
  text-transform: capitalize;
  min-width: 200px;
  text-align: center;
}

.nav-button {
  background: transparent;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button:hover {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}

.modal-content {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #2d3748;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #718096;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
}

.modal-close:hover {
  color: #4a5568;
}

.modal-body {
  padding: 1rem;
}

.modal-info-row {
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.modal-info-row:last-child {
  border-bottom: none;
}

.modal-info-row strong {
  display: inline-block;
  width: 120px;
  color: #4a5568;
}

/* Team list styling */
.team-list {
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.team-member {
  margin-bottom: 0.25rem;
}

/* Mobile Optimization */
@media (max-width: 640px) {
  .planning-container {
    padding: 1rem;
  }
  
  .event-grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  
  .event-time {
    text-align: left;
    justify-self: start;
  }
  
  .event-title {
    white-space: normal;
  }
  
  .event-subitems {
    text-align: left;
    padding-left: 1rem;
    margin-top: 0.5rem;
    border-left: none;
    min-width: auto;
  }
  
  .event-subitem {
    white-space: normal;
  }
  
  .planning-header h1 {
    font-size: 1.5rem;
  }
  
  .month-navigation h2 {
    min-width: 150px;
    font-size: 1.1rem;
  }
  
  .nav-button {
    width: 35px;
    padding: 0.4rem;
  }
  
  .event-card {
    padding: 0.75rem;
  }
  
  .modal-content {
    max-height: 80vh;
    margin: 1rem;
  }
  
  .modal-header h3 {
    font-size: 1.1rem;
  }
  
  .modal-info-row {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .modal-info-row strong {
    width: 100%;
  }
}