/* General Styles */
body, html {
    height: auto;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: Arial, sans-serif;
    background-color: #f5f7fa;
    width: 100vw;
}

/* Container Styles */
.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
}
.offerte-dropdown-container {
    position: relative;
    width: 100%;
  }
  
  .offerte-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
/* Loading animation */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  .selected-date {
    background-color: #000000 !important;
    color: white !important;
    font-weight: bold !important;
  }
/* Update the CSS for recent options */
.recent-options-header {
    font-weight: bold;
    padding: 8px 10px;
    background-color: #f5f5f5;
    font-size: 0.9em;
    color: #555;
  }
  
  .recent-option {
    background-color: #f9f9f9;
    position: relative;
  }
  
  .recent-icon {
    font-size: 0.8em;
    color: #777;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .separator {
    height: 1px;
    background-color: #ddd;
    margin: 5px 0;
  }
  
  /* Ensure the offerte-number and offerte-klant have consistent positioning */
  .offerte-number {
    display: inline-block;
    width: 30%;
    padding-right: 10px;
  }
  
  .offerte-klant {
    display: inline-block;
    width: 60%;
    color: #555;
  }
  .highlighted-date.selected-date {
    background-color: #000000 !important;
    color: white !important;
    border: 2px solid #2684ff !important;
    font-weight: bold !important;
  }
  .loading-spinner::after {
    content: "";
    width: 30px;
    height: 30px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3b82f6;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  .input-disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .planned-date {
    background-color: #fbd38d !important;
  }
  /* Notification */
  .notification {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 1rem;
    animation: slideIn 0.3s ease-out;
    z-index: 1000;
    max-width: 400px;
  }
  
  .notification.success {
    background-color: #10b981;
    color: white;
  }
  
  .notification.error {
    background-color: #ef4444;
    color: white;
  }
  
  .notification button {
    background: none;
    border: none;
    color: currentColor;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 0.25rem;
    line-height: 1;
  }
  
  @keyframes slideIn {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }

  .offerte-option {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  
  .offerte-option:hover {
    background-color: #f5f5f5;
  }
  
  .offerte-number {
    font-weight: 500;
    color: #2c3e50;
  }
  
  .offerte-klant {
    color: #666;
    font-size: 0.9em;
  }
  .register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    background-color: #ffffff;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Headers */
h2 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    color: #2c3e50;
    text-align: center;
}

/* Calendar Styles */
.calendar-container {
    margin: 1.5rem 0;
    width: 100%;
    max-width: 450px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.react-calendar {
    width: 100% !important;
    max-width: 450px;
    border: 1px solid #e2e8f0 !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
/* Add to your RegisterHours.css */
.registered-hours-container .hours-table {
    min-width: 100%;
    table-layout: auto;
}

.registered-hours-container .hours-table th,
.registered-hours-container .hours-table td {
    padding: 8px 10px;
}

/* Add horizontal scrolling indicator */
.scrollable::after {
    content: "⟷";
    position: absolute;
    bottom: -15px;
    right: 10px;
    font-size: 16px;
    color: #666;
    animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 1; }
}
.highlighted-date {
    background-color: #3b82f6 !important;
    border-radius: 6px !important;
    color: white !important;
    font-weight: bold !important;
}
/* Add to your RegisterHours.css */
.offerte-klant {
    display: inline-block;
    width: 60%;
    color: #555;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .offerte-location {
    color: #777;
    font-style: italic;
  }

/* Form Styles */
.register-form {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

.input-group {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    color: #4a5568;
    text-align: center;
    font-weight: 500;
}

.input-group input,
.input-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.95rem;
    box-sizing: border-box;
    transition: all 0.2s ease;
    text-align: center;
    background-color: #ffffff;
}

.input-group input:focus,
.input-group select:focus {
    border-color: #3b82f6;
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

/* Buttons */
.register-button,
.toggle-calendar-button {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    background-color: transparent;
    color: #4a5568;
    font-size: 0.9rem;
    font-weight: 500;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    margin-right: auto;
}

.toggle-calendar-button:hover {
    background-color: #f7fafc;
    border-color: #cbd5e0;
}

/* Table Containers */
.registered-hours-container,
.planned-hours-container {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 600px;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box;
}

.planned-hours-container {
    background-color: #f8fafc;
}

/* Table Styling */
.hours-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0.5rem 0;
    background-color: #ffffff;
    border-radius: 8px;
    position: relative;
    max-width: 100%;
}

.hours-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.95rem;
    color: #2c3e50;
}

.hours-table th {
    background-color: #f1f5f9;
    color: #334155;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    border-bottom: 2px solid #e2e8f0;
    white-space: nowrap;
}

.hours-table td {
    padding: 8px;
    border-bottom: 1px solid #e2e8f0;
    text-align: center;
}

/* Delete Button */
.delete-button {
    /* background-color: #ef4444; */
    color: white;
    padding: 5px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: background-color 0.2s ease;
}
.highlighted-date.incomplete {
    background-color: #3b83f6b1 !important;
  }
  
  .highlighted-date.selected-date.incomplete {
    background-color: #2b6cb0 !important;
  }
.delete-button:hover {
    background-color: #ffa6a6;
}

/* Target the date column specifically */
.hours-table th:first-child,
.hours-table td:first-child {
    width: 90px; /* Set a narrower fixed width */
    min-width: 90px;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Samenvatting styling */
.samenvatting-container {
    width: 100%;
    text-align: center;
    position: relative;
    margin: 15px 0;
}

.samenvatting-container::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background-color: #e2e8f0;
    z-index: 0;
}

.samenvatting-text {
    position: relative;
    background-color: white;
    padding: 0 10px;
    font-weight: bold;
    display: inline-block;
    z-index: 1;
}

/* ULTRA-AGGRESSIVE MOBILE FIXES */
@media (max-width: 500px) {
    /* Global table adjustments */
    table.hours-table {
        width: 100%;
        min-width: 100% !important; 
        max-width: 100% !important;
        table-layout: fixed !important;
        font-size: 0.6rem !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    
    /* Force table to NOT scroll horizontally */
    .hours-table-container {
        width: 100% !important;
        max-width: 100% !important;
        overflow-x: visible !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    
    /* Extreme width reduction for all columns */
    .hours-table th, 
    .hours-table td {
        padding: 4px 1px !important;
        font-size: 0.6rem !important;
        width: auto !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        text-align: center !important;
    }
    
    /* Make date column VERY narrow */
    .hours-table th:first-child,
    .hours-table td:first-child {
        width: 40px !important;
        min-width: 40px !important;
        max-width: 40px !important;
        font-size: 0.55rem !important;
    }
    
    /* Force specific widths for all columns */
    .hours-table th:nth-child(2),
    .hours-table td:nth-child(2),
    .hours-table th:nth-child(3),
    .hours-table td:nth-child(3) {
        width: 45px !important;
        min-width: 45px !important;
        max-width: 45px !important;
    }
    
    .hours-table th:nth-child(4),
    .hours-table td:nth-child(4) {
        width: 35px !important;
        min-width: 35px !important;
        max-width: 35px !important;
    }
    
    .hours-table th:nth-child(5),
    .hours-table td:nth-child(5) {
        width: 40px !important;
        min-width: 40px !important;
        max-width: 40px !important;
    }
    
    .hours-table th:nth-child(6),
    .hours-table td:nth-child(6) {
        width: 40px !important;
        min-width: 40px !important;
        max-width: 40px !important;
    }
    
    /* Action column adjustments */
    .hours-table th:last-child,
    .hours-table td:last-child {
        width: 30px !important;
        min-width: 30px !important;
        max-width: 30px !important;
    }
    
    /* Improve Offerte and Type display */
    .hours-table tr td:nth-child(2),
    .hours-table tr td:nth-child(3) {
        font-size: 0.5rem !important;
    }

    /* Compact delete button */
    .delete-button {
        padding: 2px 4px !important;
        font-size: 0.7rem !important;
    }
    
    /* Header simplification */
    .hours-table th {
        font-size: 0.55rem !important;
        font-weight: bold !important;
        padding: 4px 1px !important;
    }
    
    /* Extra DATUM formatting adjustments */
    .hours-table tr td:first-child {
        font-size: 0.55rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* Force container adjustments */
    .registered-hours-container,
    .planned-hours-container {
        padding: 0.5rem !important;
        margin-top: 1rem !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    
    /* Section headers */
    h3 {
        font-size: 1rem !important;
        margin: 0.5rem 0 !important;
    }
    
    /* Necessary padding adjustments */
    .register-container {
        padding: 0.5rem !important;
    }
    
    /* Summary table special handling */
    .summary-container .hours-table {
        width: 100% !important;
        min-width: unset !important;
    }
    
    .summary-container .hours-table th,
    .summary-container .hours-table td {
        width: 50% !important;
    }
    
    /* Make "17-03-2025" text smaller */
    .hours-table tr td[data-date="17-03-2025"],
    .hours-table tr td:first-child {
        font-size: 0.5rem !important;
    }
    
    /* Adjust Samenvatting header */
    .samenvatting-header,
    .samenvatting-text {
        font-size: 0.9rem !important;
        padding: 5px 0 !important;
    }
    
    /* Fix specific header width issues */
    th[data-header="DATUM"] {
        width: 45px !important;
        min-width: 45px !important;
        max-width: 45px !important;
    }
    
    th[data-header="STARTTIJD"],
    th[data-header="EINDTIJD"] {
        width: 45px !important;
        min-width: 45px !important;
        max-width: 45px !important;
    }
    
    th[data-header="UREN"],
    th[data-header="PAUZE"] {
        width: 35px !important;
        min-width: 35px !important;
        max-width: 35px !important;
    }
    
    /* For Samenvatting table */
    th[data-header="SOORT UUR"],
    th[data-header="GEWERKTE UREN"] {
        width: 50% !important;
    }
    
    /* Extreme mobile adjustments */
    @media (max-width: 375px) {
        .hours-table th:first-child,
        .hours-table td:first-child {
            width: 35px !important;
            min-width: 35px !important;
            max-width: 35px !important;
            font-size: 0.5rem !important;
        }
        
        .hours-table th,
        .hours-table td {
            font-size: 0.55rem !important;
            padding: 3px 1px !important;
        }
        
        .hours-table th:nth-child(2),
        .hours-table td:nth-child(2),
        .hours-table th:nth-child(3),
        .hours-table td:nth-child(3) {
            width: 40px !important;
            min-width: 40px !important;
            max-width: 40px !important;
        }
        
        .hours-table th:nth-child(4),
        .hours-table td:nth-child(4),
        .hours-table th:nth-child(5),
        .hours-table td:nth-child(5) {
            width: 30px !important;
            min-width: 30px !important;
            max-width: 30px !important;
        }
    }
}

/* Desktop Adjustments */
@media (min-width: 1024px) {
    .register-container {
        max-width: 800px;
    }

    .register-form {
        max-width: 500px;
    }
}

/* Scroll Indicator */
.hours-table-container::-webkit-scrollbar {
    height: 4px;
}

.hours-table-container::-webkit-scrollbar-track {
    background: #f1f5f9;
}

.hours-table-container::-webkit-scrollbar-thumb {
    background: #94a3b8;
    border-radius: 2px;
}