/* Base Styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  width: 100%;
  /* height: 100%; */
  overflow-x: hidden;
}

body {
  font-family: 'Inter', 'Segoe UI', 'Arial', sans-serif;
  background-color: #f4f4f4;
}

/* Layout */
.content {
  transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  min-height: 100vh;
  background: #fff;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.content.shift {
  margin-left: 280px;
}
.login-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f8ff;
}
/* Sidebar Menu */
.sidebar {
  position: fixed;
  left: -280px;
  top: 0;
  width: 280px;
  height: 100%;
  background: linear-gradient(180deg, #1a1a1a 0%, #2d2d2d 100%);
  color: #e1e1e1;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 4px 0 25px rgba(0, 0, 0, 0.15);
  z-index: 1100; /* Increased z-index */
  display: flex;
  flex-direction: column;
}
.login-container {
  position: relative;
  z-index: 1200;
}
 
 .sidebar.open {
  left: 0;
 }
 
 .sidebar-header {
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
 }
 
 .sidebar-logo {
  height: 40px;
  width: auto;
 }
 
 .sidebar-menu {
  list-style: none;
  padding: 20px 0;
  margin: 0;
  flex: 1;
 }
 
 .sidebar li {
  padding: 4px 16px;
  margin: 8px 12px;
 }
 
 .sidebar-link {
  color: #e1e1e1;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  gap: 12px;
 }
 
 .sidebar-link::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background: rgba(255, 255, 255, 0.05);
  transition: width 0.2s ease;
 }
 
 .sidebar-link:hover::before {
  width: 100%;
 }
 
 .sidebar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #e1e1e1;
 }
 
 .sidebar-label {
  font-size: 15px;
  font-weight: 500;
 }
 
 .logout-button {
  margin: 20px 16px;
  padding: 12px 20px;
  background-color: rgba(239, 68, 68, 0.1);
  color: #e1e1e1;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s ease;
  width: calc(100% - 32px);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
 }
 
 .logout-button:hover {
  background-color: rgba(239, 68, 68, 0.2);
  border-color: rgba(255, 255, 255, 0.15);
 }
 
 .sidebar-link.active {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-weight: 500;
 }
 
 .sidebar-link:hover {
  background: rgba(255, 255, 255, 0.05);
 }
 
 /* Hamburger Icon */
 .menu-icon {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  background: rgba(26, 26, 26, 0.95);
  color: #ffffff;
  padding: 12px 14px;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1101;
  /* backdrop-filter: blur(8px); */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
 }
 
 .menu-icon:hover {
  background: rgba(45, 45, 45, 0.95);
 }
 
 .menu-icon.rotating {
  transform: rotate(90deg);
 }
 
 .sidebar.open + .menu-icon {
  left: 290px;
 }
 
 /* Content */
 .content {
  transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  min-height: 100vh;
  background: #fff;
  position: relative;
 }
 
 .content.shift {
  margin-left: 280px;
 }
 
/* Add these to App.css for global styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #f4f4f4;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 16px;
  overflow-x: hidden;
  position: relative;
}

/* Update Dashboard.css */
.dashboard-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 16px;
  background-color: #f8fafc;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Add/update these styles while keeping all existing styles */

/* Base Reset and Centering */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

#root {
  display: flex;
  flex-direction: column;
}

/* Update existing .content class */
.content {
  transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  min-height: 100vh;
  background: #fff;
  position: relative;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Update existing .content.shift */
.content.shift {
  margin-left: 280px;
}

/* Update sidebar mobile styles */
@media (max-width: 768px) {
  .sidebar {
    width: 100vw;
    max-width: 100%;
    left: -100vw;
  }

  .content {
    width: 100%;
    margin-left: 0;
    padding: 16px;
    overflow-x: hidden;
  }

  .content.shift {
    margin-left: 0;
  }
}

@media (max-width: 390px) {
  .content {
    padding: 8px;
  }
}

/* Media queries for better mobile handling */
@media (max-width: 768px) {
  .content {
    padding: 12px;
    margin-left: 0;
  }
  .content.shift {
    margin-left: 0;
    opacity: 0.5;
    /* pointer-events: none; */
  }
  .sidebar {
    width: 100vw;
    max-width: 100%;
    left: -100vw;
  }
  /* .content::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s ease;
    z-index: 1050;
  } */
  
  /* .content.shift::after {
    background: rgba(0, 0, 0, 0.2);
  } */

  .dashboard-container {
    padding: 12px;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 390px) {
  .content {
    padding: 8px;
  }

  .dashboard-container {
    padding: 8px;
  }

  .grid-card {
    margin: 0 -8px;
    border-radius: 0;
  }
}.sidebar.open + .content {
  transform: none;  /* Remove any transform that might cause horizontal scroll */
}
.content.shift {
  margin-left: 0;
}
/* .content::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease;
} */

.content.shift::after {
  background: rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(4px); */
  /* pointer-events: auto; */
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    width: 100vw; /* Use viewport width instead of percentage */
    max-width: 100%;
  }

  .content.shift {
    transform: none;
    margin-left: 0;
  }
}

 /* Mobile Responsiveness */
 @media (max-width: 768px) {
  .content {
    width: 100%;
    margin-left: 0;
    padding: 16px;
  }
 
  .content.shift {
    margin-left: 0;
    opacity: 0.5;
    /* pointer-events: none; */
  }
 
  .sidebar {
    width: 100%;
    left: -100%;
  }
 
  .sidebar.open {
    left: 0;
  }
 
  .sidebar.open + .menu-icon {
    left: calc(100% - 60px);
  }
 
  .sidebar-header {
    padding: 16px;
  }
 
  .menu-icon {
    top: 12px;
    left: 12px;
    padding: 10px;
  }
 
  .logout-button {
    margin: 16px;
    padding: 12px 16px;
  }
 }
 
 /* Add blur effect when menu is open on mobile */
 @media (max-width: 768px) {
  .content.shift::after {
    background: rgba(0, 0, 0, 0.2);
    /* backdrop-filter: blur(4px); */
  }
 }