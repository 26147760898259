/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Ensure body takes up full height without scrolling */
html, body {
    height: 100%;
    width: 100%;
    overflow: hidden; /* Prevent scrolling */
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

/* Root container for full height */
#root {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Center the login container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    background-color: #f0f8ff;
}

/* Login form: Centered & responsive */
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
}

/* Login form title */
.login-form h2 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #333;
    text-align: center;
}

/* Input group styling */
.input-group {
    width: 100%;
    margin-bottom: 1rem;
}

.input-group label {
    width: 100%;
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.3rem;
}

.input-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.input-group input:focus {
    border-color: #0056b3;
    outline: none;
}

/* Login button */
.login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #0056b3;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #004494;
}

/* Logo styling with black background and soft edges */
.login-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black; /* Black background */
    padding: 1rem; /* Space around logo */
    border-radius: 12px; /* Soft edges */
    width: 220px; /* Adjust to fit the logo */
    height: 80px;
    margin-bottom: 1rem; /* Space below the logo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for professional look */
    animation: fadeIn 1.5s ease-in-out; /* Apply animation */
}

/* Logo inside container */
.login-logo {
    width: 100%;
    height: auto;
  /*  filter: brightness(0) invert(1); */ /* Makes logo visible on black background */
}

/* Professional fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Make sure everything fits nicely on mobile */
@media (max-width: 480px) {
    .login-container {
        padding: 1rem;
    }

    .login-form {
        padding: 1.5rem;
        width: 100%;
    }

    .login-form h2 {
        font-size: 1.6rem;
    }

    .input-group input, 
    .login-button {
        font-size: 1rem;
        padding: 0.6rem;
    }
}