/* Vacation.css */

.vacation-container {
    padding: 16px;
    max-width: 1000px;
    margin: 0 auto !important;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  }
  
  .page-title {
    font-size: 20px;
    font-weight: 600;
    color: #1f2937;
    margin: 0 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 16px;
    letter-spacing: -0.01em;
    text-align: center;
  }
  
  .title-icon {
    color: #3b82f6;
  }
  
  .filter-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
    background: linear-gradient(135deg, #f9fafb, #f3f4f6);
    border-radius: 8px;
    padding: 16px 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }
  
  .select-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .select-container label {
    font-size: 14px;
    color: #64748b;
    font-weight: 500;
  }
  
  .select-container select {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: white;
    font-size: 14px;
    color: #1e293b;
    min-width: 140px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;
    transition: border-color 0.15s ease-in-out;
  }
  
  .select-container select:focus {
    outline: none;
    border-color: #93c5fd;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }
  
  .select-container select:hover {
    border-color: #9ca3af;
  }
  
  /* Summary Cards */
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .summary-card {
    background: white;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .summary-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
  }
  
  .card-icon {
    background: #e0f2fe;
    border-radius: 8px;
    padding: 8px;
    color: #3b82f6;
  }
  
  .card-content {
    flex: 1;
  }
  
  .card-content h3 {
    font-size: 13px;
    color: #64748b;
    margin: 0 0 8px 0;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  
  .card-value {
    font-size: 28px;
    font-weight: 600;
    color: #1e293b;
    line-height: 1;
    letter-spacing: -0.02em;
  }
  
  .card-value.positive {
    color: #2563eb;
  }
  
  .card-value.negative {
    color: #dc2626;
  }
  
  /* Table Styles */
  .table-container {
    background: white;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }
  
  .table-title {
    font-size: 16px;
    color: #1e293b;
    margin: 0 0 16px 0;
    font-weight: 600;
    padding-bottom: 12px;
    border-bottom: 1px solid #f3f4f6;
  }
  
  .responsive-table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -20px;
    padding: 0 20px;
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
  }
  
  table {
    width: 100%;
    min-width: 340px; /* Further reduced minimum width */
    border-collapse: separate;
    border-spacing: 0;
    font-size: 14px;
    table-layout: fixed; /* Added to control column widths */
  }
  
  /* Add column width specifications */
  th:first-child, td:first-child {
    width: 40%;
  }
  
  th:nth-child(2), td:nth-child(2),
  th:nth-child(3), td:nth-child(3),
  th:nth-child(4), td:nth-child(4) {
    width: 20%;
    text-align: center;
  }
  
  th {
    text-align: left;
    padding: 12px 16px;
    background-color: #f9fafb;
    color: #64748b;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    border-bottom: 1px solid #e5e7eb;
  }
  
  td {
    padding: 14px 16px;
    border-bottom: 1px solid #f3f4f6;
    color: #1e293b;
    font-size: 14px;
  }
  
  tr:last-child td {
    border-bottom: none;
  }
  
  tr:hover {
    background-color: #f8fafc;
  }
  
  td.positive {
    color: #1d4ed8;
  }
  
  td.negative {
    color: #dc2626;
  }
  
  tr.active-row {
    background-color: #f0f9ff;
  }
  
  /* Chart Containers */
  .chart-container {
    background: white;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }
  
  .chart-title {
    font-size: 16px;
    color: #1e293b;
    margin: 0 0 16px 0;
    font-weight: 600;
    padding-bottom: 12px;
    border-bottom: 1px solid #f3f4f6;
  }
  
  /* Placeholder Chart */
  .placeholder-chart {
    background: #f8fafc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
  }
  
  .chart-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background: #f1f5f9;
    border-radius: 8px;
    color: #64748b;
    font-size: 14px;
  }
  
  .chart-placeholder code {
    margin-top: 8px;
    background: #e2e8f0;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: monospace;
  }
  
  /* Loading State */
  .loading-spinner {
    width: 32px;
    height: 32px;
    border: 3px solid #e2e8f0;
    border-top: 3px solid #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .vacation-container.loading,
  .vacation-container.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    text-align: center;
  }
  
  .retry-button {
    margin-top: 20px;
    padding: 10px 18px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .retry-button:hover {
    background-color: #1d4ed8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .retry-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3);
  }
  
  .retry-button:active {
    background-color: #1e40af;
    transform: translateY(1px);
  }
  
  /* Error State */
  .vacation-container.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    text-align: center;
    color: #64748b;
    background: white;
    border-radius: 6px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }
  
  .vacation-container.error svg {
    color: #64748b;
    margin-bottom: 16px;
  }
  
  .vacation-container.error h2 {
    font-size: 18px;
    font-weight: 600;
    color: #1e293b;
    margin: 0 0 8px 0;
  }
  
  .vacation-container.error p {
    color: #64748b;
    margin: 0 0 20px 0;
    font-size: 14px;
    max-width: 80%;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .vacation-container {
      padding: 12px;
    }
    
    .summary-cards {
      grid-template-columns: 1fr;
    }
    
    .filter-controls {
      flex-direction: column;
      gap: 12px;
    }
    
    .table-title {
      font-size: 15px;
    }
    
    th {
      padding: 10px 8px;
      font-size: 11px;
    }
    
    td {
      padding: 12px 8px;
      font-size: 13px;
    }
    
    .responsive-table {
      margin: 0 -16px;
      padding: 0 16px;
      width: calc(100% + 32px);
      left: -16px;
    }
    
    .table-container {
      padding: 16px;
    }
    
    .chart-container {
      padding: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .vacation-container {
      padding: 10px;
    }
    
    .table-container,
    .chart-container {
      padding: 12px;
      margin-bottom: 16px;
    }
    
    .table-title,
    .chart-title {
      font-size: 14px;
      margin-bottom: 12px;
    }
    
    table {
      min-width: 320px;
    }
    
    th {
      padding: 8px 4px;
      font-size: 10px;
    }
    
    td {
      padding: 10px 4px;
      font-size: 12px;
    }
    
    .responsive-table {
      margin: 0 -12px;
      padding: 0 12px;
      width: calc(100% + 24px);
      left: -12px;
    }
    
    .card-value {
      font-size: 24px;
    }
    
    /* Further optimize table header text */
    th:first-child {
      text-align: left;
    }
  }