/* Dashboard.css */

.dashboard-container {
    padding: 16px;
    max-width: 1000px;
    margin: 0 auto !important;
    width: 100%;
    /* text-align: center; */
  }
  
  .dashboard-welcome {
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center; /* Changed from space-between to center */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
  }
  
  .welcome-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center text */
    text-align: center; /* Center text */
  }
  
  .welcome-content h1 {
    font-size: 20px;
    font-weight: 500;
    color: #1f2937;
    margin: 0;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 16px;
    margin-bottom: 16px;
    width: 100%;
  }
  
  .stat-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .stat-icon {
    font-size: 20px;
    background: #f1f5f9;
    padding: 8px;
    border-radius: 8px;
  }
  
  .stat-content {
    flex: 1;
  }
  
  .stat-content h3 {
    font-size: 14px;
    color: #64748b;
    margin: 0 0 4px 0;
    font-weight: 500;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: 600;
    color: #1e293b;
    line-height: 1;
  }
  
  .stat-label {
    font-size: 12px;
    color: #94a3b8;
    margin-top: 4px;
  }
  
  .dashboard-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .hours-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .hours-entry {
    display: grid;
    grid-template-columns: 85px 3fr 140px;
    padding: 0 20px;
    padding: 4px;
    background: #007bf500;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .hours-time {
    font-weight: 500;
    color: #1e293b;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .hours-info {
    color: #64748b;
    font-size: 12px;
    margin-top: 2px;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .hours-werk {
    color: #64748b;
    font-size: 12px;
    padding-left: 5px;
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .grid-card {
    background: white;
    border-radius: 12px !important;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
    margin: 0 auto !important;
  }
  .hours-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 16px;
  }
  
  .hours-modal-content {
    background: white;
    border-radius: 8px;
    padding: 16px;
    max-width: 400px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .hours-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .hours-modal-title {
    font-size: 16px;
    font-weight: 600;
    color: #1e293b;
  }
  
  .hours-modal-close {
    background: none;
    border: none;
    font-size: 20px;
    color: #64748b;
    cursor: pointer;
    padding: 4px;
  }
  .grid-card h2 {
    font-size: 16px;
    color: #1e293b;
    margin: 0 0 16px 0;
    font-weight: 600;
  }
  
  /* Calendar Styles */
  .month-calendar {
    width: 100%;
    background: white;
    border-radius: 8px; 
    padding: 12px 8px;
    box-sizing: border-box;
  }
  
  .calendar-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    margin-bottom: 4px;
  }
  
  .calendar-header div {
    text-align: center;
    color: #64748b;
    font-size: 12px;
    font-weight: 500;
    padding: 4px;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    width: 100%;
  }
  
  .calendar-day {
    border-radius: 12px;
    aspect-ratio: 1;
    min-width: 0; /* Add this to prevent overflow */
    font-size: calc(10px + 0.2vw); /* Responsive font size */
  }
  
  .calendar-day {
    cursor: pointer;
  }
  .calendar-day:hover {
    transform: scale(1.02);
    transition: transform 0.2s ease;
  }
  .more-entries {
    cursor: pointer;
    color: #4a5568;
    font-size: 11px;
    margin-top: 2px;
  }
  .modal-info-row {
    margin-bottom: 16px;
  }
  .team-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 8px;
  }
  
  .team-member {
    font-size: 14px;
    color: #64748b;
    margin-bottom: 4px;
    padding-left: 4px;
  }
  
  .more-entries:hover {
    color: #2d3748;
  }
  .calendar-day.empty {
    background: none;
  }
  
  .calendar-day.weekend {
    background-color: #f1f5f9;
  }
  
  .calendar-day.no-hours {
    background-color: #fee2e2;
  }
  
  .calendar-day.incomplete {
    background-color: #fef3c7;
  }
  
  .calendar-day.complete {
    background-color: #dbeafe;
  }
  
  .day-number {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  
  .day-content {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .day-hours {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  
  .day-entries {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .time-entry {
    font-size: 11px;
    padding: 2px 4px;
    background: rgba(255, 255, 255, 0);
    border-radius: 2px;
  }
  
  .more-entries {
    font-size: 10px;
    color: #666;
    padding: 2px 4px;
  }
  
  /* Loading & Error States */
  .dashboard-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    gap: 16px;
  }
  
  .loader {
    width: 32px;
    height: 32px;
    border: 3px solid #e2e8f0;
    border-top: 3px solid #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .dashboard-error {
    background: #fee2e2;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
    color: #dc2626;
  }
  
  .dashboard-error h3 {
    margin: 0 0 8px 0;
    font-size: 16px;
  }
  
  .dashboard-error p {
    margin: 0;
    color: #ef4444;
    font-size: 14px;
  }
  
  /* Events List Styles */
  .events-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .event-card {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    background: #f8fafc;
    border-radius: 6px;
    border-left: 3px solid #2563eb;
  }
  
  .event-time {
    background: #2563eb;
    color: white;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .event-info h4 {
    font-size: 14px;
    font-weight: 500;
    color: #1e293b;
    margin: 0 0 2px 0;
  }
  
  .event-info p {
    font-size: 12px;
    color: #64748b;
    margin: 0;
  }
  
  .no-events {
    text-align: center;
    padding: 24px;
    color: #64748b;
    font-size: 14px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .dashboard-container {
      padding: 12px;
    }
  
    .calendar-grid {
      gap: 2px;
    }
  
    .calendar-day {
      padding: 4px;
    }
  
    .day-entries {
      display: none;
    }
  
    .day-number {
      font-size: 11px;
      text-align: center;
    }
  
    .day-hours {
      font-size: 12px;
      text-align: center;
    }
  }
  @media (min-width: 430px) {
    .calendar-day {
      padding: 8px;
    }
    
    .day-number {
      font-size: 14px;
    }
    
    .day-hours {
      font-size: 14px;
    }
  }
  
  @media (max-width: 430px) {
    .dashboard-container {
      padding: 8px;
    }
  
    .calendar-day {
      padding: 4px;
    }
    
    .day-number {
      font-size: 11px;
    }
    
    .day-hours {
      font-size: 11px;
    }
  }
  
  @media (width: 390px) {
    .dashboard-container {
      padding: 8px;
    }
  
    .calendar-grid {
      gap: 2px;
    }
  
    .calendar-day {
      padding: 2px;
      min-height: 40px; /* Add minimum height */
      aspect-ratio: 1;
      display: flex;
      border-radius: 4px; 
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .calendar-grid {
        gap: 2px;
        width: 100%;
      }
  
    .grid-card {
        padding: 12px;
        margin: 0;
        border-radius: 12px; /* Maintain border radius */
        width: calc(100% - 0px); /* Ensure full width minus padding */
      }
  
      .month-calendar {
        padding: 8px;
        margin: 0; /* Remove negative margins */
        width: 100%;
      }
  
    .day-number {
      font-size: 10px;
    }
  
    .day-hours {
      font-size: 10px;
    }
  
    .stats-grid {
        margin: 0 0 16px 0; /* Remove negative margins */
        width: 100%;
      }
  }
  @media (min-width: 391px) {
    .calendar-day {
      min-height: 50px;
    }
  }